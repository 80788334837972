body {
  margin: 0;
  /* font-family: 'IBM Plex Serif', serif; */
  font-weight: 500px;
  overflow-x: hidden;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  /* font-family: 'IBM Plex Serif', serif; */
}

.primaryBGColor {
  background-color: #24357c !important;
}

.bgMainpage {
  background-image: url('./Images/banner-new.png');
  background-attachment: local;
  background-repeat: round;
  height: 600px !important;
}

.bgFooterpage {
  background-image: url('./OIFendImages/images/main_footer.png');
  background-attachment: local;
  background-repeat: round;
  background-color: white;
  height: 500px;
}

.whyImage {
  width: 50% !important;
}

.textWhy {
  text-align: left !important;
}

.textMainContent {
  /* background-color: rgb(33 34 156 / 43%); */
  width: 85% !important;
}

.flexRow {
  display: flex !important;
  flex-flow: row wrap !important;
  flex-direction: row !important;
}

@media (max-width: 560px) {
  .h200 {
    height: 100px !important;
  }

  .bgMainpage {
    background-image: url('./Images/banner-new.png');
    /* background-color: rgba(43, 60, 207, 0.87); */
    background-attachment: local;
    background-repeat: round;
    height: 44vh !important;
  }

  .bgFooterpage {
    /* background-image: url('./OIFendImages/images/Footerbg.png');  */
    /* background-attachment: local; */
    background-repeat: round;
    background-color: white;
    height: auto !important;
  }

  .whyImage {
    width: 100% !important;
  }

  .textWhy {
    text-align: center !important;
  }

  .textMainContent {
    background-color: rgb(33 34 156 / 43%);
    width: 100% !important;
  }

  .mainBannerContent {
    text-align: center !important;
    justify-items: center !important;
  }

  .flexRow {
    display: flex !important;
    flex-flow: row wrap !important;
    flex-direction: column !important;
  }

}