.bz-custom-section {
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 440px;
}


.bz-custom-section .overlay {
    position: absolute;
    inset: 0;
    background-image: linear-gradient(to right top, #184388, #005b9b, #0073a9, #008ab4, #23a0bc);
    opacity: 0.85;
    height: 100%;
    width: 100%;
}

.h-100 {
    height: 100%;
}

.custom-service-border {
    border-top: 3px solid #24357C;
    /* border-bottom: 3px solid #24357C; */
}

/* .bz-custom-section::after {
    content: '';
    position: absolute;
    inset: 0;
    background-image: linear-gradient(to right top, #184388, #005b9b, #0073a9, #008ab4, #23a0bc);
    opacity: 0.85;
} */