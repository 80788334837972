.mobileView {
    display: block !important;
    height: 100px !important;
    margin-top: -30px !important;
    padding-top: 0px !important;
}

.mobileViewShow {
    display: block !important;
}

.bgPrimaryColor {
    background-image: linear-gradient(to right top, #184388, #005b9b, #0073a9, #008ab4, #23a0bc);
}

.primaryColor {
    color: #24357c;
}

.desktopView {
    display: none !important;
}

.custom-dropdown-menu {
    display: none;
    width: 100%;
}

.custom-dropdown-item {
    color: #1890ff;
}

.relative:hover .custom-dropdown-menu {
    display: block !important;
}

@media (min-width:320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
}

@media (min-width:480px) {
    /* smartphones, Android phones, landscape iPhone */
}

@media (max-width:1000px) {

    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    .mobileView {
        display: none !important;
    }

    .mobileViewShow {
        display: none !important;
    }

    .desktopView {
        display: block !important;
        /* height: 100px !important; */
        /* margin-top: -30px !important; */
        padding-top: 0px !important;
    }
}

@media (min-width:801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
}

@media (min-width:1025px) {
    /* big landscape tablets, laptops, and desktops */
}

@media (min-width:1281px) {
    /* hi-res laptops and desktops */
}

/* @media (min-width: 768px){
.mobileView {
    display: none !important;
}
} */