/* @import "react-alice-carousel/lib/alice-carousel.css"; */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1100px;
  }
}

.react-multiple-carousel__arrow--right {
  right: calc(4% + 1px);
  margin-right: -3.5rem;
  min-height: 100px !important;
  background-color: #5c887936 !important;
  border-radius: 13px !important;
  cursor: pointer !important;
  font-weight: bolder !important;
  color: black !important;
}

.react-multiple-carousel__arrow--left {
  left: calc(4% + 1px);
  margin-left: -3.5rem;
  min-height: 100px !important;
  background-color: #5c887936 !important;
  border-radius: 13px !important;
  cursor: pointer !important;
  font-weight: bolder !important;
  color: black !important;
}

.react-multiple-carousel__arrow--left:hover,
.react-multiple-carousel__arrow--right:hover {
  transform: scale(1.2);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.custom-list {
  margin: 0;
  list-style: none;
}

.loader-container {
  position: fixed;
  background-color: #00000069;
  inset: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-container .ant-spin-dot i {
  background-color: #fff !important;
}

.loader-container .ant-spin-text {
  color: #fff !important;
}

.custom-datepicker.ant-picker {
  border-radius: 9999px !important;
  line-height: 1.25 !important;
  padding: .61rem 1rem !important;
  background-color: #F9FAFB !important;
}

.ant-select-selector {
  border-radius: 9999px !important;
  line-height: 1.25 !important;
  padding: .3rem 1rem !important;
  background-color: #F9FAFB !important;
  height: 43.5px !important;
}

.custom-datepicker.ant-picker-focused {
  background-color: #fff !important;
}

input[type='text']:hover,
input[type='text']:focus {
  border-color: #40a9ff !important;
  border-right-width: 1px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (max-width:768px) {
  .padding-mob {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .mob-order-2 {
    order: 2 !important;
  }

  .hero-heading,
  .hero-sub-heading,
  .mob-text-center {
    text-align: center !important;
  }

  .mob-mt-30px {
    margin-top: 30px !important;
  }

  .section-heading {
    font-size: 25px !important;
  }

  .bz-custom-section .section-heading {
    text-align: center !important;
  }

  /* .bz-custom-section .icon-mob-fix {
    justify-content: center !important;
  } */

  .bz-custom-section .icon-mob-fix.last-item {
    margin-bottom: 50px !important;
  }

}

@media (max-width:992px) {}

@media (max-width:1023px) {
  .section-3 .second-col {
    margin-top: 30px !important;
  }
}