.custom-list {
    margin: 0;
    list-style: none;
}

.custom-list>li {
    display: flex;
    align-items: center;
}

.custom-list>li>img {
    width: 20px;
    margin-right: 5px;
}


/* The container */
.container-checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom checkbox */
.container-checkbox .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: #eee;
    border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.container-checkbox:hover input~.checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked~.checkmark {
    background-image: linear-gradient(to right top, #184388, #005b9b, #0073a9, #008ab4, #23a0bc);
}

/* Create the checkmark/indicator (hidden when not checked) */
.container-checkbox .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container-checkbox input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
    left: 8px;
    top: 4px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}



/* ****************************** Testimonial Stlye ****************************** */
.testimonail-container {
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
}

.testimonail-container::before {
    content: '';
    background-color: #24357C;
    position: absolute;
    inset: 0px;
    opacity: 0.6;
    height: 100%;
    width: 100%;
    z-index: 0;

}

.testimonial-heading {
    color: #ffffff;
    font-size: 35px;
    text-transform: uppercase;
    font-weight: bold;
    z-index: 1;
}



.testimonial-slider {
    position: relative;
    width: 100%;
    overflow: hidden;
    text-align: center;
}

.slide {
    opacity: 0;
    transition: opacity 0.6s ease;
    position: absolute;
    width: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.testimonial-image {
    height: 70px;
    border-radius: 50%;
}

.slide.active {
    opacity: 1;
    position: relative;
}

.testimonial-content {
    color: #ffffff;
    font-size: 20px;
    line-height: 1.4;
    margin: 20px 0 20px 0;
}

.testimonial-name {
    color: #ffffff;
    font-size: 22px;
    font-weight: bold;
}

.dots {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.dot {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.dot.active {
    background-color: #333;
}

/* ****************************** Google Review Stlye ****************************** */
.review-section {
    padding: 3em 2em 6em 2em;
    background: #f9f9f9;
    text-align: center;
}

.section-title {
    text-transform: uppercase;
}

.review-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1em;
}

.review-card {
    background: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 1.5em;
    max-width: 350px;
    margin: 1em;
    font-family: Arial, sans-serif;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: default;
}

.review-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.review-header {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
}

.review-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 1em;
    border: 2px solid #f1f1f1;
}

.review-name {
    font-size: 1.1em;
    font-weight: bold;
    margin: 0;
}

.review-rating {
    display: flex;
    font-size: 1.2em;
    color: #FFD700;
    /* Gold color for filled stars */
}

.star {
    color: #ddd;
    /* Light color for unfilled stars */
}

.star.filled {
    color: #FFD700;
}

.review-text {
    font-size: 1em;
    line-height: 1.4;
    color: #555;
    margin: 0;
}

.ant-collapse-header {
    font-weight: 500;
    font-size: 0.8rem;
    color: #1176df !important;
}

.ant-image-preview-img-wrapper {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.ant-image-preview-img-wrapper img {
    max-width: 95% !important;
    max-height: 95% !important;
}

.upload-btn:hover,
.upload-btn:focus {
    border-color: #40a9ff !important;
    border-right-width: 1px !important;
    color: #40a9ff !important;
}